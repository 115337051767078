.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #41C087;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #3aae7a;
}
.button:active {
  background-color: #2d885f;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.61290323%;
  margin-left: 1.61290323%;
  width: 96.77419355%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 21.77419355%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--two .area {
  width: 100%;
}
.section--two .area > .unit {
  margin-right: 1.61290323%;
  margin-left: 1.61290323%;
  width: 96.77419355%;
}
.section--two .area .cb-album .body,
.section--two .area .head,
.section--two .area .foot,
.section--two .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim {
  width: 46.77419355%;
}
.section--two .area > .slim .head,
.section--two .area > .slim .foot,
.section--two .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 102.34741784%;
  margin-left: -1.17370892%;
  display: flex;
  flex-wrap: wrap;
}
.section--two .area {
  width: 103.33333333%;
  margin-left: -1.66666667%;
  display: flex;
  flex-wrap: wrap;
}
.section--one .area .seam.wide,
.section--one .area .edge.wide {
  width: 63.44086022%;
}
.section--one .area .seam.slim,
.section--one .area .edge.slim {
  width: 30.10752688%;
}
.section--one .area .flat.slim {
  width: 46.77419355%;
}
.section--two .area .pure.wide .body {
  width: 103.33333333%;
  margin-left: -1.66666667%;
}
.section--two .area .pure.wide .part {
  margin-left: 1.61290323%;
  margin-right: 1.61290323%;
}
.section--two .area .pure.wide .part.tall {
  width: 96.77419355%;
}
.section--two .area .pure.wide .part.tiny {
  width: 46.77419355%;
}
/*# sourceMappingURL=./screen-medium.css.map */